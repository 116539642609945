<template>
  <HeaderBlock msg="HeaderBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">{{ $t('ULooperUpdater') }}</h1>
          </div>
        </div>
      </div>
    </div> <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="card card-lg">
          <div class="row g-4">
            <div class="col-md-12">
              <div class="card-body">
                <h2>Windows</h2>
                <ul>
                  <li v-for="item in downloadItems" v-bind:key="item" >
                    <a :href="'/upload/ulooper_updater/ULooper_Firmware_Updater_Win_v' + item.version+(item.isBeta?'_Beta':'')+ '.zip?v='+item.updateTime" v-if="item.isDownload">ULooper_Firmware_Updater_Win_v{{
                    item.version+(item.isBeta?'_Beta':'')
                  }}.zip</a>
                  </li>
                </ul>
                <h2>MacOS</h2>
                <ul>
                  <li v-for="item in downloadItems" v-bind:key="item" >
                    <a :href="'/upload/ulooper_updater/ULooper_Firmware_Updater_Mac_v' + item.version+(item.isBeta?'_Beta':'')+ '.zip?v='+item.updateTime" v-if="item.isDownload">ULooper_Firmware_Updater_Mac_v{{
                    item.version+(item.isBeta?'_Beta':'')
                  }}.zip</a>
                  </li>
                </ul>
                <h2>{{ $t('ReleaseNote') }}</h2>
                <div  v-for="item in items" v-bind:key="item">
                  <h3>V{{item.version}} <span v-if="item.isBeta">Beta</span></h3>
                  <ul>
                    <li v-for="it in get_content(item).split('\n')" v-bind:key="it">{{it.replace("* ","")}}</li>
                  </ul>
                  <div v-if="get_note(item)!=''"><b>{{ $t('BetaTips') }}</b> {{ get_note(item) }}<br/><br/></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>
<script>
import HeaderBlock from '../components/HeaderBlock.vue'
import FooterBlock from '../components/FooterBlock.vue'
//
import { global } from '../global.js'
export default {
  name: 'ULooperUpdaterPage',
  data() {
    return {
      global: global,
      downloadItems: [],
      items: [],
    };
  },  
  mounted() {
    this.i18n = this.$i18n.locale;
    this.get_item();
  },
  methods: {
    get_note(item) {
      if (this.$i18n.locale == 'zh') {
        return item.noteZh;
      }else{
        return item.noteEn;
      }
    },
    get_content(item) {
      if (this.$i18n.locale == 'zh') {
        return item.contentZh;
      }else{
        return item.contentEn;
      }
    },
    get_item() {
      global.postData("help_center/uplooer_updater", { id: this.$route.params.id }, { token: true })
        .then(data => {
          if (data != null && data.code == 200) {
            this.items = data.body;
            this.items.forEach(item => {
              if (item.isDownload) {
              this.downloadItems.push(item);
              }
            });

          }
        });
    }
  },
  components: {
    HeaderBlock,
    FooterBlock,
  },
}
</script>
<style scoped>
.card-body {
  padding: 5%;
}
</style>